<style lang="scss" scoped>
  .v-snackbar {

    :deep(.v-snackbar__wrapper) {
      border: 1px solid $brandDarkRed;
      background-color: $brandLightRed;
      box-shadow: 0px 8px 24px 0px rgba(84, 98, 209, 0.24) !important;
    }

    :deep(div > *) {
      color: $brandDarkRed;
    }
  }
</style>

<template>
  <v-snackbar
    v-model="visible"
    location="top"
    timeout="-1"
  >
    <h4 class="mb-2">
      {{ title }}
    </h4>
    {{ text }}
    <template #actions>
      <v-btn @click="onClose">
        <v-icon icon="mdi-close" size="24" />
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:modelValue']);

const visible = ref(props.modelValue);

watch(() => props.modelValue, function (newValue) {
  visible.value = newValue;
});

function onClose () {
  visible.value = false;
  emit('update:modelValue', false);
}

</script>
